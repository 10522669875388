import { iStandardButtonIcons } from "../types"
import {
    IconAddBorderless,
    IconAddCollaborator,
    IconArchive,
    IconBundle,
    IconBundleAction,
    IconCollapseAll,
    IconCopy,
    IconCostCode,
    IconCraftWorker,
    IconDownload,
    IconDownloadAll,
    IconEdit,
    IconExpandAll,
    IconExport,
    IconFind,
    IconFormVariant,
    IconGroup,
    IconHide,
    IconHistory,
    IconImport,
    IconPaste,
    IconRefresh,
    IconRemove,
    IconSettings,
    IconSignature,
    IconShare,
    IconStatus,
    IconTrash,
    IconUpload,
    IconDeactivate,
    IconDocumentGeneric,
    IconLaunch,
    IconView,
    IconX,
} from "@rhumbix/rmbx_design_system_web"

const standardIcons: iStandardButtonIcons = {
    add: () =>
        new IconAddBorderless({ height: "15", width: "15", viewBox: "0 -2 25 25", className: "toolbar-btn-icon" }),
    addCollaborator: () => IconAddCollaborator({ className: "toolbar-btn-icon" }),
    archive: () => new IconArchive({ className: "toolbar-btn-icon" }),
    bulkview: () => new IconDocumentGeneric({ className: "toolbar-btn-icon" }),
    bundle: () => new IconBundleAction({ className: "toolbar-btn-icon" }),
    collaborator: () => new IconAddCollaborator({ className: "toolbar-btn-icon" }),
    collapse: () => new IconCollapseAll({ className: "toolbar-btn-icon" }),
    copy: () => new IconCopy({ className: "toolbar-btn-icon" }),
    copyAction: () => new IconBundle({ className: "toolbar-btn-icon" }),
    costCode: () => new IconCostCode({ className: "toolbar-btn-icon" }),
    download: () => new IconDownload({ className: "toolbar-btn-icon" }),
    download_all: () => new IconDownloadAll({ className: "toolbar-btn-icon" }),
    deactivate: () => new IconDeactivate({ className: "toolbar-btn-icon" }),
    delete: () => new IconTrash({ className: "toolbar-btn-icon" }),
    edit: () => new IconEdit({ className: "toolbar-btn-icon" }),
    expand: () => new IconExpandAll({ className: "toolbar-btn-icon" }),
    export: () => new IconExport({ className: "toolbar-btn-icon" }),
    find: () => new IconFind({ className: "toolbar-btn-icon" }),
    group: () => new IconGroup({ viewBox: "0 0 20 20", className: "toolbar-btn-icon" }),
    hide: () => new IconHide({ className: "toolbar-btn-icon" }),
    history: () => new IconHistory({ className: "toolbar-btn-icon" }),
    import: () => new IconImport({ className: "toolbar-btn-icon" }),
    launch: () => new IconLaunch({ className: "toolbar-btn-icon" }),
    paste: () => new IconPaste({ className: "toolbar-btn-icon" }),
    refresh: () => new IconRefresh({ className: "toolbar-btn-icon" }),
    remove: () => new IconRemove({ className: "toolbar-btn-icon" }),
    settings: () => new IconSettings({ className: "toolbar-btn-icon" }),
    share: () => new IconShare({ className: "toolbar-btn-icon" }),
    signature: () => new IconSignature({ className: "toolbar-btn-icon" }),
    status: () => new IconStatus({ className: "toolbar-btn-icon" }),
    upload: () => new IconUpload({ className: "toolbar-btn-icon" }),
    variant: () => new IconFormVariant({ className: "toolbar-btn-icon" }),
    view: () => new IconView({ className: "toolbar-btn-icon" }),
    worker: () => new IconCraftWorker({ className: "toolbar-btn-icon" }),
    x: () => new IconX({ className: "toolbar-btn-icon" }),
}

export default standardIcons
