import React, { Component } from "react"
import { bulkUpdateSourceDataFieldByGroupKeyInfo } from "../../../actions/bulk-actions"
import { HoursSegment, TotalColumnHoursSegment } from "./cell-renderer-util"
import {
    isPivotRowTotalColumn,
    roleHasWritePermission,
    getValuePivotCellClass,
    getTotalPivotCellClass,
} from "../../../common/ag-grid-utils"
import { getGroupKeyInfo } from "../../../common/ag-grid-grouping-utils"
import {
    colorFunctionalBlack,
    colorFunctionalWhite,
    IconAbsent,
    IconAddModifier,
    IconShiftExtra,
    IconSignature,
    IconTimeCard,
} from "@rhumbix/rmbx_design_system_web"
import { statusesByUserRole } from "../../../common/constants"
import { getFlagEnabled } from "../../../getFlagValue"
import { connect } from "react-redux"
import { Tooltip as ReactTooltip } from "react-tooltip"
import Portal from "react-overlays/Portal"
import {
    pivotTotalColumnCustomTooltipMessageBuilder,
    pivotValueColumnCustomTooltipMessageBuilder,
} from "../../../common/ag-grid-custom-tooltip-formatters"
import { formatTimeDiff, getCicoIcon, getCicoIconColor } from "../utils"

class TimecardCellRenderer extends Component {
    constructor(props) {
        super(props)
        this.updateStatus = this.updateStatus.bind(this)
    }

    getFontColor = color => {
        if (!color) return "#fff"
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
        const rgbColor = result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null

        const uicolors = [rgbColor.r / 255, rgbColor.g / 255, rgbColor.b / 255]
        const c = uicolors.map(col => {
            if (col <= 0.03928) {
                return col / 12.92
            }
            return Math.pow((col + 0.055) / 1.055, 2.4)
        })
        const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
        return L > 0.179 ? colorFunctionalBlack : colorFunctionalWhite
    }

    updateStatus(event) {
        event.stopPropagation()
        const status = event.target.value
        const pivotColumns = this.props.columnApi.getPivotColumns()
        const groupKeyInfo = getGroupKeyInfo(
            this.props.node,
            this.props.column,
            pivotColumns,
            this.props.context.referenceableData
        )
        this.props.context.dispatch(
            bulkUpdateSourceDataFieldByGroupKeyInfo(groupKeyInfo, "status", status, this.props.context)
        )
    }

    renderIcons() {
        const cicoIconSpot = document.getElementById("master-container")
        const totalTime = (this.props.value.ST || 0) + (this.props.value.DT || 0) + (this.props.value.OT || 0)
        const workingDuration =
            (this.props.value.clocked_in_duration || 0) / 60 -
            (this.props.value.meal_duration || 0) / 60
        const timeDiff = ["/cost_code", "/status", "/work_shift_id"].includes(this.props.node.field)
            ? 0
            : totalTime - workingDuration
        const CicoIcon = getCicoIcon(timeDiff)
        const cicoIconColor = getCicoIconColor(timeDiff)
        const editCount = this.props.value.version_count - this.props.value.item_count
        return (
            <div className="icon-row">
                <div className={"icon-item"}>
                    {"clocked_in_duration" in this.props.value ? (
                        <div id={`cico-icon-${this.props.node.id}`}>
                            <Portal container={cicoIconSpot}>
                                <ReactTooltip
                                    id={`cico-tooltip-${this.props.node.id}`}
                                    place="bottom"
                                    variant="dark"
                                    render={attrs => {
                                        const { activeAnchor } = attrs
                                        const timeDiffStr = ["/cost_code", "/status", "/work_shift_id"].includes(
                                            this.props.node.field
                                        )
                                            ? ""
                                            : `, ${formatTimeDiff(timeDiff)}`
                                        return (
                                            <span style={{ textAlign: "left", display: "block" }}>
                                                <span style={{ fontWeight: "bold" }}>
                                                    {workingDuration.toFixed(2)} CI/CO Hours {timeDiffStr}
                                                </span>
                                                <br />
                                                {("0" + activeAnchor?.getAttribute("data-num-shifts")).slice(
                                                    -2
                                                )}{" "}
                                                Shifts
                                                <br />
                                                {editCount > 0 ? `${editCount} Edits` : ""}
                                            </span>
                                        )
                                    }}
                                />
                            </Portal>
                            <CicoIcon
                                alt={"clock-in/clock-out icon"}
                                data-clocked-time={workingDuration}
                                data-tooltip-id={`cico-tooltip-${this.props.node.id}`}
                                data-num-shifts={this.props.value.shift_count || 0}
                                data-tooltip-position-strategy={"fixed"}
                                style={{ color: cicoIconColor }}
                            />
                        </div>
                    ) : null}
                </div>
                <div className="icon-item">
                    {"absenceReason" in this.props.value ? (
                        <div id="absence-icon">
                            <IconAbsent title="Absence" />
                        </div>
                    ) : null}
                </div>
                <div className="icon-item">
                    {"shiftExtra" in this.props.value ? (
                        <div id="shift-extra-icon">
                            <IconShiftExtra title="Shift Extra" />
                        </div>
                    ) : null}
                </div>
                <div className="icon-item">
                    {"signature" in this.props.value && this.props.value["signature"] ? (
                        <div id="signature-icon">
                            <IconSignature title="Signature" />
                        </div>
                    ) : null}
                </div>
                <div className="icon-item">
                    {this.props.value?.modifierActive ? (
                        <IconAddModifier className="modifier-badge" title="Modifier" />
                    ) : null}
                </div>
                <div className="icon-item">
                    {"startStopTypes" in this.props.value && this.props.value["startStopTypes"]?.size ? (
                        <div id="start-stop-icon">
                            <IconTimeCard title="Shift Start/Stop Times" />
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }

    createStyleForStatus = status => ({
        backgroundColor: status?.is_outline ? "" : `#${status ? status.selected_color : "CCCCCC"}`,
        borderWidth: status?.is_outline ? "2px" : "1px",
        borderColor: status?.is_outline ? `#${status.selected_color}` : colorFunctionalBlack,
        fontWeight: 500,
        color: status?.is_outline ? `#${status?.selected_color}` : this.getFontColor(status?.selected_color),
    })
    renderApproveButton() {
        const { user_role } = this.props.context.currentUser
        if (!this.props.value.status || this.props.node.field === "cost_code") {
            return null
        }
        const statusValueArray = Array.from(this.props.value.status).filter(status => status !== "__NONE__")
        const editableStatuses = this.props.timekeepingStatuses
            ? Object.values(this.props.timekeepingStatuses.objects).filter(tk_status =>
                  tk_status.can_edit_cell?.includes(user_role)
              )
            : []

        const buttonDisabled = statusValueArray.every(
            status => !editableStatuses.find(tk_status => tk_status.name === status)
        )

        // if the button is disable we need to show the status the user doesn't have access to otherwise
        // it'll appear they can't transition a status they do have access to. Since it's disabled we'll
        // just populate all statuses. In the enabled state we'll only give them statuses they can work with.
        const transitionableStatuses = buttonDisabled
            ? Object.values(this.props.timekeepingStatuses.objects)
            : this.props.timekeepingStatuses
            ? Object.values(this.props.timekeepingStatuses.objects).filter(
                  tk_status => tk_status.can_change_to_status?.includes(user_role) && !tk_status.is_hidden
              )
            : []
        // SYNCED should always exist, but it might be possible that the timekeeping status referenceable
        // objects aren't loaded yet, so we need to check for that. We make sure to check that this
        // is defined below before trying to access it
        const syncedStatus = this.props.timekeepingStatuses
            ? Object.values(this.props.timekeepingStatuses.objects).find(s => s.name === "SYNCED")
            : null

        let css = null
        let style = this.createStyleForStatus(syncedStatus)
        let onlySyncedStatus = false

        css = "select-dropdown"
        // Pick up the styles for the first status in the set of statuses
        if (statusValueArray.length === 1) {
            if ("SYNCED" in statusValueArray && syncedStatus) onlySyncedStatus = true
            Object.values(this.props.timekeepingStatuses?.objects || {})
                .sort((a, b) => (a.sort_order < b.sort_order ? 1 : -1))
                .forEach(tkStatus => {
                    if (statusValueArray.includes(tkStatus.name)) {
                        style = this.createStyleForStatus(tkStatus)
                    }
                })
        } else if (statusValueArray.length > 1) {
            style = this.createStyleForStatus({ is_outline: false, selected_color: "CCCCCC" })
        }

        const firstStatusOption = statusValueArray[0]
        const multipleStatuses = statusValueArray.length > 1 ? ["Multiple Statuses"] : []
        if (getFlagEnabled("WA-8101-bulk-status-update-fix")) {
            if (statusValueArray.length === 0) {
                return
            }
            return (
                <div className={css} style={{ ...style, backgroundColor: "" }}>
                    <React.Fragment>
                        <select
                            id="total-cell-status-select"
                            onChange={this.updateStatus}
                            value={multipleStatuses.length ? multipleStatuses[0] : firstStatusOption}
                            disabled={buttonDisabled}
                            style={style}
                        >
                            {[
                                ...multipleStatuses.map(s => (
                                    <option value={s} key={-1}>
                                        {s}
                                    </option>
                                )),
                                ...(onlySyncedStatus
                                    ? [
                                          <option value={syncedStatus.name} key={0}>
                                              {syncedStatus.label}
                                          </option>,
                                      ]
                                    : transitionableStatuses
                                          .sort((a, b) => (a.sort_order < b.sort_order ? -1 : 1))
                                          .map((item, index) => {
                                              return (
                                                  <option value={item.name} key={index}>
                                                      {item.label}
                                                  </option>
                                              )
                                          })),
                            ]}
                        </select>
                        <div
                            className="timecard-select__arrow"
                            style={{
                                borderColor: style.color
                                    ? `${style.color} transparent transparent transparent`
                                    : "",
                            }}
                        />
                    </React.Fragment>
                </div>
            )
        }
        return (
            <div className={css}>
                {this.props.value.status.size > 1 ? (
                    <div className="multiple-statuses">Multiple Statuses</div>
                ) : firstStatusOption === "SYNCED" ? (
                    <div className="synced-status-select">Synced</div>
                ) : (
                    <React.Fragment>
                        <select
                            id="total-cell-status-select"
                            onChange={this.updateStatus}
                            value={firstStatusOption}
                            disabled={!roleHasWritePermission(firstStatusOption, user_role)}
                        >
                            {statusesByUserRole.map((item, index) => {
                                if (
                                    this.props.value.status.has(item.name) ||
                                    item.canChangeToStatus.includes(user_role)
                                ) {
                                    return (
                                        <option value={item.name} key={index}>
                                            {item.label}
                                        </option>
                                    )
                                }
                            })}
                        </select>
                        <div className="timecard-select__arrow" />
                    </React.Fragment>
                )}
            </div>
        )
    }

    render() {
        const tooltipInfo = pivotValueColumnCustomTooltipMessageBuilder({
            colDef: this.props.colDef,
            column: this.props.column,
            columnApi: this.props.columnApi,
            context: this.props.context,
            node: this.props.node,
            value: this.props.value,
        })

        const tooltipArray = Array.isArray(tooltipInfo.data?.messages)
            ? tooltipInfo.data?.messages
            : tooltipInfo.data?.messages
            ? [tooltipInfo.data.messages]
            : []

        if (isPivotRowTotalColumn(this.props.colDef)) {
            const totalTooltipInfo = pivotTotalColumnCustomTooltipMessageBuilder({
                colDef: this.props.colDef,
                column: this.props.column,
                columnApi: this.props.columnApi,
                context: this.props.context,
                node: this.props.node,
                value: this.props.value,
            })

            const cellClass = getTotalPivotCellClass(this.props)
            return (
                <div className={`week-total ${cellClass}`}>
                    <TotalColumnHoursSegment value={this.props.value} tooltip={totalTooltipInfo.data?.messages} />
                    {this.renderApproveButton()}
                </div>
            )
        } else {
            const className = this.props.inline ? "one-timekeeping-value-row" : "two-timekeeping-value-rows"
            const cellClass = getValuePivotCellClass(this.props)
            return (
                <div id={this.props.colDef.headerName} className={"weekly-tk-timecard-cell"}>
                    <div className={`${className} ${cellClass}`}>
                        <HoursSegment
                            value={this.props.value}
                            tooltips={tooltipArray}
                            nodeId={this.props.node.id}
                        />
                        {this.renderIcons()}
                    </div>
                    {this.props.value.status && this.props.value.status.size > 0 ? (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className="traffic-lights">
                                {Object.values(this.props.timekeepingStatuses.objects)
                                    .sort((a, b) => (a.sort_order < b.sort_order ? -1 : 1))
                                    .map((value, index) => {
                                        if (index < 4) {
                                            const enableTrafficLight =
                                                this.props.value.status.has(value.name) ||
                                                (index === 3 && this.props.value.status.has("SYNCED"))
                                            // If we're in the fourth traffic light and we have a synced status,
                                            // we use that bubble to display synced.
                                            const status =
                                                index === 3 && this.props.value.status.has("SYNCED")
                                                    ? {
                                                          selected_color: "CCCCCC",
                                                          traffic_color: "606060",
                                                          is_outline: false,
                                                          is_traffic_outline: false,
                                                      }
                                                    : value
                                            return (
                                                <div
                                                    key={index}
                                                    style={
                                                        enableTrafficLight
                                                            ? {
                                                                  backgroundColor: status.is_traffic_outline
                                                                      ? colorFunctionalWhite
                                                                      : `#${status.traffic_color}`,
                                                                  borderWidth: status.is_traffic_outline
                                                                      ? "2px"
                                                                      : "1px",
                                                                  borderColor: status.is_traffic_outline
                                                                      ? `#${status.traffic_color}`
                                                                      : colorFunctionalBlack,
                                                              }
                                                            : null
                                                    }
                                                />
                                            )
                                        }
                                    })}
                            </div>
                            {Object.values(this.props.timekeepingStatuses.objects).length > 4 && (
                                <div
                                    style={{
                                        color: "#DDD",
                                        marginTop: -6,
                                        marginLeft: -1,
                                    }}
                                >
                                    &#8230;
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        timekeepingStatuses: state.entities.timekeepingStatuses,
        cicoExceptions: state.searchBar.cicoExceptions,
        cicoExceptionValue: state.searchBar.cicoExceptionValue,
        cicoEnabled: state.current_user.features.cico,
    }
}
export default connect(mapStateToProps)(TimecardCellRenderer)
