import { loadAllEntities } from "../../../cached-data/actions"
import { emailValueSetter, employeeRoleValueSetter, phoneValueSetter } from "../../../common/ag-grid-value-setters"

import {
    phoneNumberFormatter,
    titleCaseFormatter,
    filterFormatter,
    isActiveValueFormatter,
    dateTimeValueFormatter,
    declinableBooleanValueFormatter,
    emailFormatter,
} from "../../../common/ag-grid-value-formatters"
import { getFlagEnabled } from "../../../getFlagValue"
import { EMPLOYEE_ROLE_DEFAULT_OPTION, EMPLOYEE_ROLE_OPTIONS } from "../../../common/constants"
import { isValidEmail, isLowercaseEmail } from "../../../common/validators"
import { publicApiControlsTooltip } from "../../../common/ag-grid-custom-tooltip-formatters"
import {
    checkboxColDefCreator,
    stringColDefCreator,
    booleanColDefCreator,
    employeeGroupsColDefCreator,
    equipmentColDefCreator,
    datetimeColDefCreator,
    companyCrewTypeColDefCreator,
    picklistColDefCreator,
    employeeCohortsColDefCreator,
    modifyButtonColDefCreator,
} from "./standard-col-def-creators"
import {
    companyGroupsFilterDef,
    employeeRoleFilterDef,
    employeeStatusFilterDef,
} from "../../../filters/filter-defs"
import { textFieldOptionCreator } from "../../../filters/filter-def-creators"

export function getEmployeesSettings(featureFlags, _currentProject, currentUser) {
    const is_editable = featureFlags && !featureFlags.read_only_employees
    const cellClasses = is_editable || getFlagEnabled("WA-8444-modify-employee-rr") ? [] : ["readonly"]
    const showLaborType = featureFlags.cost_code_controls
    const pinned = getFlagEnabled("WA-8305-freeze-employee-columns") ? { pinned: "left" } : {}

    const textFieldOptions =
        getFlagEnabled("WA-8402-custom-text-fields") && currentUser && currentUser.text_field_options
            ? currentUser.text_field_options.filter(option => option.model === "Employee")
            : []

    const textFieldOptionColDefs = textFieldOptions.map(option =>
        stringColDefCreator({
            headerName: option.label,
            field: `/custom_fields/${option.name}`,
            cellClass: option.read_only_in_app ? ["readonly"] : [],
            editable: !option.read_only_in_app,
        })
    )

    const textFieldOptionfilterDefs = textFieldOptions.map(option =>
        textFieldOptionCreator(option.label, option.model, option.name)
    )
    const defaultTooltip = getFlagEnabled("WA-8444-modify-employee-rr")
        ? {}
        : { customTooltip: publicApiControlsTooltip }

    return {
        tableName: "Employees",
        navId: "employees",
        resources: ["employees"],
        relatedNames: {
            employees: {
                equipment: ["/default_equipment"],
            },
        },
        initAction: () => dispatch => {
            // Load the groups so that we can selectively access and assign users
            // to the Main group
            dispatch(loadAllEntities("companyGroups"))
        },
        filters: getFlagEnabled("WA-7860-employee-page-filters")
            ? [companyGroupsFilterDef, employeeRoleFilterDef, employeeStatusFilterDef, ...textFieldOptionfilterDefs]
            : [],
        colDefs: [
            {
                field: "/project",
                hide: true,
            },
            ...(is_editable
                ? [
                      checkboxColDefCreator({
                          headerCheckboxSelection: false,
                          ...pinned,
                      }),
                  ]
                : []),
            stringColDefCreator({
                headerName: "Employee ID*",
                field: "/company_supplied_id",
                headerTooltip: "Your internal company id",
                required: true,
                ...pinned,
            }),
            stringColDefCreator({
                headerName: "First Name*",
                field: "/first_name",
                required: true,
                ...pinned,
            }),
            stringColDefCreator({
                headerName: "Last Name*",
                field: "/last_name",
                required: true,
                ...pinned,
            }),
            {
                ...stringColDefCreator({
                    headerName: "Role*",
                    field: "/user_role",
                    width: 120,
                    enum: EMPLOYEE_ROLE_OPTIONS,
                    default: EMPLOYEE_ROLE_DEFAULT_OPTION,
                    // we always want this to be editable, even if nothing else is
                    editable: true,
                    required: true,
                }),
                valueFormatter: titleCaseFormatter,
                valueSetter: employeeRoleValueSetter,
                customTooltip: undefined,
                cellClass: [],
            },
            {
                ...stringColDefCreator({
                    headerName: "Mobile Phone",
                    field: "/phone",
                    headerTooltip: "Required for Foremen",
                }),
                valueFormatter: phoneNumberFormatter,
                valueSetter: phoneValueSetter,
            },
            {
                ...stringColDefCreator({
                    headerName: "Email",
                    field: "/email",
                    width: 230,
                    headerTooltip: "Required for roles other than Worker",
                }),
                cellEditorParams: {
                    validators: [isValidEmail, isLowercaseEmail],
                },
                ...(getFlagEnabled("WA-8383-lowercase-emails")
                    ? {
                          valueFormatter: emailFormatter,
                          valueSetter: emailValueSetter,
                      }
                    : {}),
            },
            ...(getFlagEnabled("WA-8444-modify-employee-rr")
                ? [
                      {
                          headerName: "Login Enabled",
                          valueFormatter: params => {
                              return params.data.email && params.data.user_is_active ? "Yes" : "No"
                          },
                          cellRendererParams: {
                              computedField: true,
                          },
                          field: "/login_enabled",
                          editable: false,
                          minWidth: 140,
                      },
                      {
                          ...booleanColDefCreator({
                              headerName: "Text Alerts OK",
                              field: "/text_alerts_ok",
                              minWidth: 100,
                              editable: false,
                              default: false,
                          }),
                          valueFormatter: params => (params.data.text_alerts_ok ? "Yes" : "No"),
                      },
                  ]
                : []),
            stringColDefCreator({
                headerName: "Classification",
                field: "/classification",
            }),
            stringColDefCreator({
                headerName: "Trade",
                field: "/trade",
            }),
            ...(featureFlags.cost_code_level_modifiers
                ? [
                      equipmentColDefCreator({
                          field: "/default_equipment",
                          required: false,
                          editable: false,
                          excludeFromExport: true,
                      }),
                  ]
                : []),
            companyCrewTypeColDefCreator({
                alphabetize: true,
                hide: !showLaborType,
            }),
            picklistColDefCreator("Certifications", "/certifications", "Certification"),
            picklistColDefCreator("Licenses", "/licenses", "License"),
            picklistColDefCreator("Unions", "/unions", "Union"),
            employeeGroupsColDefCreator({
                groupsCompanyFeatureToggleActive: featureFlags?.groups,
                excludeFromExport: !featureFlags?.groups,
                alphabetize: true,
            }),
            employeeCohortsColDefCreator({ includeHidden: true }),
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    minWidth: 100,
                    editable: false,
                    default: true,
                    uneditableMessage: "Select checkbox beside Employee ID to update this field",
                }),
                valueFormatter: isActiveValueFormatter,
            },

            {
                ...stringColDefCreator({
                    headerName: "Disabled Status",
                    field: "/disabled_status",
                    width: 140,
                    enum: ["YES", "NO", "DECLINE"],
                    default: "",
                    valueFormatter: declinableBooleanValueFormatter,
                    required: false,
                }),
            },
            {
                ...stringColDefCreator({
                    headerName: "Veteran Status",
                    field: "/veteran_status",
                    width: 140,
                    enum: ["YES", "NO", "DECLINE"],
                    default: "",
                    valueFormatter: declinableBooleanValueFormatter,
                    required: false,
                }),
            },
            ...(featureFlags.cico
                ? [
                      stringColDefCreator({
                          headerName: "PIN",
                          field: "/cico_pin",
                          width: 230,
                          default: "",
                          required: false,
                      }),
                      stringColDefCreator({
                          headerName: "QR Code",
                          field: "/cico_qr_code",
                          width: 230,
                          default: "",
                          required: false,
                      }),
                  ]
                : []),
            datetimeColDefCreator({
                headerName: "Created On",
                editable: false,
                field: "/created_on",
                valueFormatter: dateTimeValueFormatter,
                // Override the default controlled by public api tooltip
                customTooltip: () => {
                    return {
                        tooltipShouldRender: false,
                        data: {
                            messages: [],
                        },
                    }
                },
            }),
            ...textFieldOptionColDefs,
            datetimeColDefCreator({
                headerName: "Last Active",
                editable: false,
                field: "/last_active",
                valueFormatter: dateTimeValueFormatter,
                // Override the default controlled by public api tooltip
                customTooltip: () => {
                    return {
                        tooltipShouldRender: false,
                        data: {
                            messages: [],
                        },
                    }
                },
            }),
            ...(getFlagEnabled("WA-8444-modify-employee-rr")
                ? [
                      {
                          ...modifyButtonColDefCreator({
                              modifyFlow: "EMPLOYEE_MODIFY",
                              headerName: "Settings",
                              field: "/settings",
                          }),
                          editable: true,
                          excludeFromExport: true,
                      },
                  ]
                : []),
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                cellClass: cellClasses,
                ...defaultTooltip,
                editable: is_editable,
                getQuickFilterText: filterFormatter,
            },
            // allow our click events to happen
            suppressRowClickSelection: true,
            rowSelection: "multiple",
            // SSRM settings
            rowModelType: "serverSide",
            serverSideStoreType: "partial",
            cacheBlockSize: 100,
        },
        otherSettings: {
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            hiddenColumnDefaults: {
                project: "projectId",
            },
            buttons: {
                cell: [
                    ...(is_editable
                        ? [
                              {
                                  label: "Edit",
                                  icon: "edit",
                                  action: "editFocusedCell",
                              },
                              {
                                  label: "Copy",
                                  icon: "copy",
                                  action: "copyFocusedCell",
                              },
                              {
                                  label: "Paste",
                                  icon: "paste",
                                  action: "pasteIntoFocusedCell",
                              },
                          ]
                        : []),
                ],
                row: [
                    ...(is_editable
                        ? [
                              {
                                  label: "Activate",
                                  icon: "view",
                                  action: "bulkUpdateField",
                                  args: {
                                      field: "is_active",
                                      value: true,
                                      has_signal: true,
                                  },
                              },
                              {
                                  label: "Deactivate",
                                  icon: "hide",
                                  action: "bulkUpdateField",
                                  args: {
                                      field: "is_active",
                                      value: false,
                                      has_signal: true,
                                  },
                              },
                              { separator: true },
                              { label: "Delete", icon: "delete", action: "deleteSelectedRows" },
                          ]
                        : []),
                    ...(getFlagEnabled("WA-8444-modify-employee-rr")
                        ? [
                              {
                                  label: "Invite to Rhumbix App",
                                  action: "sendWelcomeMail",
                                  icon: "worker",
                                  disabled: context => context.selectedRows.some(row => !row.user_id || !row.email),
                                  tooltip: context => {
                                      if (context.selectedRows.some(row => !row.email))
                                          return "Some of the selected employees do not have email addresses"
                                      if (context.selectedRows.some(row => !row.user_id))
                                          return "Some of the selected employees are not configured to log in"
                                      return "Opt employees in for text messages"
                                  },
                              },
                              {
                                  label: "Reset Password",
                                  action: "sendResetPasswordMail",
                                  icon: "refresh",
                                  disabled: context => context.selectedRows.some(r => !r.user_id),
                                  tooltip: context => {
                                      if (context.selectedRows.some(row => !row.user_id))
                                          return "Some of the selected employees are not configured to log in"
                                      return "Send an email with a reset password link"
                                  },
                              },
                          ]
                        : []),
                    ...(is_editable && getFlagEnabled("WA-8444-modify-employee-rr")
                        ? [
                              {
                                  label: "Enable Login",
                                  action: "toggleLogin",
                                  icon: "addCollaborator",
                                  args: {
                                      is_active: true,
                                  },
                                  disabled: context => context.selectedRows.some(r => !r.email),
                                  tooltip: context => {
                                      if (context.selectedRows.some(r => !r.email))
                                          return "Some of the selected employees do not have email addresses"
                                      return "Allows selected employees to log in to Rhumbix"
                                  },
                              },
                              {
                                  label: "Disable Login",
                                  action: "toggleLogin",
                                  icon: "remove",
                                  args: {
                                      is_active: false,
                                  },
                                  disabled: context => context.selectedRows.some(r => !r.email),
                                  tooltip: context => {
                                      if (context.selectedRows.some(r => !r.email))
                                          return "Some of the selected employees do not have email addresses"
                                      return "Removes the ability for selected employees to log in to Rhumbix"
                                  },
                              },
                              {
                                  label: "Enable Text Alerts",
                                  action: "bulkUpdateField",
                                  icon: "add",
                                  args: {
                                      field: "text_alerts_ok",
                                      value: true,
                                  },
                                  disabled: context => context.selectedRows.some(row => !row.phone),
                                  tooltip: context => {
                                      if (context.selectedRows.some(row => !row.phone))
                                          return "Some of the selected employees do not have phone numbers"
                                      return "Opt employees in for text messages"
                                  },
                              },
                              {
                                  label: "Disable Text Alerts",
                                  action: "bulkUpdateField",
                                  icon: "x",
                                  args: {
                                      field: "text_alerts_ok",
                                      value: false,
                                  },
                                  disabled: context => context.selectedRows.some(row => !row.phone),
                                  tooltip: context => {
                                      if (context.selectedRows.some(row => !row.phone))
                                          return "Some of the selected employees do not have phone numbers"
                                      return "Opt employees out of text messages"
                                  },
                              },
                          ]
                        : []),
                ],
                table: [
                    ...(is_editable
                        ? [
                              ...(getFlagEnabled("WA-8444-modify-employee-rr")
                                  ? [
                                        {
                                            label: "Add Employee",
                                            icon: "add",
                                            action: "addEmployee",
                                        },
                                    ]
                                  : [{ label: "Add Row", icon: "add", action: "addNewRow" }]),
                              {
                                  label: "Upload",
                                  icon: "upload",
                                  action: "navigateTo",
                                  args: { url: "/rhumbix/company-admin/users/upload/" },
                              },
                          ]
                        : []),
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
            rowLevelValidators: [
                data => {
                    if (
                        (data.email == null || data.email.trim().length === 0) &&
                        ["ADMIN", "OFFICE_STAFF", "PAYROLL_ADMIN", "PM", "FOREMAN"].includes(data.user_role)
                    ) {
                        data.errors["/email"] = ["This role requires an email address"]
                    }
                    if (
                        (data.phone == null || data.phone.trim().length === 0) &&
                        ["FOREMAN"].indexOf(data.user_role) > -1
                    ) {
                        data.errors["/phone"] = ["This role requires a mobile phone number"]
                    }
                    return data
                },
            ],
        },
    }
}
