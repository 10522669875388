import React, { forwardRef, useEffect, useImperativeHandle } from "react"
import { IDoesFilterPassParams, IFilterParams, RowNode } from "ag-grid-community"
import { connect } from "react-redux"
import { ReduxState } from "../../../common/types"
import { isCicoException, isHoursException } from "./utils"

type Props = IFilterParams & {
    cicoExceptionValue: { value: string; units: "hours" | "percent" }
    cicoExceptionActive: boolean
    hoursExceptionActive: boolean
    hoursExceptionValues: { minHours: string; maxHours: string }
}

/**
 * Exception filtering. We do all exceptions in this same filter currently as I'm not sure there
 * is a way to provide multiple filter components to a column in AG-Grid
 */
const ExceptionFilter = forwardRef((props: Props, ref) => {
    const checkCicoPivotFilter = (params: IDoesFilterPassParams) => {
        let currentNode = params.node
        while (currentNode) {
            if (["/employee", "/project", "/foreman"].includes(currentNode!.field as string)) break
            currentNode = currentNode.parent as RowNode
        }
        return Object.keys(currentNode.aggData).some(pivotKey => {
            const cellData = currentNode.aggData[pivotKey]
            return isCicoException(props.cicoExceptionValue, cellData)
        })
    }

    const checkHoursPivotFilter = (params: IDoesFilterPassParams) => {
        let currentNode = params.node
        while (currentNode) {
            if (["/employee"].includes(currentNode!.field as string)) break
            currentNode = currentNode.parent as RowNode
        }

        return Object.keys(currentNode.aggData).some(pivotKey => {
            const cellData = currentNode.aggData[pivotKey]
            return isHoursException(props.hoursExceptionValues, cellData)
        })
    }

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(
        ref,
        () => {
            return {
                doesFilterPass(params: IDoesFilterPassParams) {
                    if (!props.cicoExceptionActive && !props.hoursExceptionActive) return true
                    if (props.cicoExceptionActive) {
                        if (checkCicoPivotFilter(params)) return true
                    }
                    if (props.hoursExceptionActive) {
                        if (checkHoursPivotFilter(params)) return true
                    }
                    return false
                },

                isFilterActive() {
                    return props.cicoExceptionActive || props.hoursExceptionActive
                },

                // This part isn't really used, but it has to be present.
                getModel() {
                    return {
                        cicoExceptionActive: props.cicoExceptionActive,
                        hoursExceptionActive: props.hoursExceptionActive,
                    }
                },

                setModel() {},
            }
        },
        [
            props.cicoExceptionActive,
            props.cicoExceptionValue,
            props.hoursExceptionActive,
            props.hoursExceptionValues,
        ]
    )
    useEffect(() => {
        props.filterChangedCallback()
    }, [
        props.cicoExceptionActive,
        props.cicoExceptionValue,
        props.hoursExceptionActive,
        props.hoursExceptionValues,
    ])

    return <div style={{ padding: 4, width: 200 }} />
})

const mapStateToProps = (state: ReduxState) => {
    return {
        cicoExceptionActive: state.searchBar.cicoExceptions,
        cicoExceptionValue: state.searchBar.cicoExceptionValue,
        hoursExceptionActive: state.searchBar.hoursExceptions,
        hoursExceptionValues: state.searchBar.hoursExceptionValues,
    }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(ExceptionFilter)
