const canParseAsNumber = value => isFinite(value) && isFinite(parseFloat(value))

export const numberValueParser = params =>
    canParseAsNumber(params.newValue) ? Number(params.newValue) : params.newValue

export const numberOrNullValueParser = params => (params.newValue === "" ? null : numberValueParser(params))

export const hoursToMinutesValueParser = params =>
    canParseAsNumber(params.newValue) ? Math.round(params.newValue * 60) : params.newValue

/**
 * Parse entered time either in HH:MM format or in decimal hours (the classic way)
 * @param params
 * @returns {*}
 */
export const hoursMinutesToMinutesValueParser = params => {
    const parts = params.newValue.toString().split(":")
    const hours = parts.length === 1 ? parseFloat(parts[0]) : parseInt(parts[0])
    const minutes = parts.length === 1 ? 0 : parseInt(parts[1])
    return hours * 60 + minutes
}

export const USDValueParser = params =>
    // currency fields should be integers, if the value cannot be parsed as a number, replace the value with zero
    canParseAsNumber(params.newValue) ? Math.round(params.newValue * 100) : 0
