import { iEmployee } from "../../../cached-data/types"

/**
 * Determine whether a cell constitutes a CI/CO exception
 * @param exceptionValue The value and units of the exception
 * @param cellData The data for the cell
 * @param employee Optional employee object. There are different circumstances depending on where
 * this function gets called. If we have the employee, it's a little simpler as the totals have
 * already been stored there
 */
export const isCicoException = (
    exceptionValue: { value: string; units: "percent" | "hours" },
    cellData: Record<string, any>,
    employee?: iEmployee
) => {
    if (!employee) {
        const enteredHours = (cellData.ST || 0) + (cellData.OT || 0) + (cellData.DT || 0)
        const cicoHours = (cellData.clocked_in_duration || 0) / 60
        const value = parseFloat(exceptionValue.value)
        if (exceptionValue.units === "hours") return Math.abs(enteredHours - cicoHours) > value
        else {
            if (!cicoHours || !enteredHours) return true
            if (enteredHours > cicoHours) return Math.abs(1 - cicoHours / enteredHours) > value / 100
            else return Math.abs(1 - enteredHours / cicoHours) > value / 100
        }
    } else {
        const { entered_time_duration, working_duration } = employee
        const value = parseFloat(exceptionValue.value)
        if (exceptionValue.units === "hours" && Math.abs(employee.cicoDiff || 0) > value * 60) return true
        else if (exceptionValue.units === "percent" && entered_time_duration && working_duration) {
            if (
                entered_time_duration > working_duration &&
                Math.abs(1 - working_duration / entered_time_duration) > value / 100
            )
                return true
            else if (working_duration && Math.abs(1 - entered_time_duration / working_duration) > value / 100)
                return true
        }
    }
}

/**
 * Determine whether this cell represents an exception for the amount of hours configured
 * @param exceptionValue Min/max hours that we check against
 * @param cellData The cell data
 * @param employee Optional employee. If we have this, we're not in a pivot cell and the computation is
 * a little different.
 */
export const isHoursException = (
    exceptionValue: { minHours: string; maxHours: string },
    cellData: Record<string, any>,
    employee?: iEmployee
) => {
    const enteredHours = employee
        ? (employee.entered_time_duration || 0) / 60
        : cellData.ST || 0 + cellData.OT || 0 + cellData.DT || 0
    return (
        !!enteredHours &&
        (enteredHours < parseFloat(exceptionValue.minHours) || enteredHours > parseFloat(exceptionValue.maxHours))
    )
}
